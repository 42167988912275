import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = () => ({
  progress: {
    height: 10
  },
  Alert: {
    fontWeight: "Bold",
    fontSize: "12pt",
    textAlign: "center",
    marginBottom: 20
  }
});

class ErrorAlert extends PureComponent {
  render() {
    const { classes, children, visible } = this.props;
    return (
      <div
        style={{ display: visible ? "block" : "none", textAlign: "center" }}
        className={classes.AlertBlock}
      >
        <CircularProgress className={classes.progress} />
        <Typography variant="h4" gutterBottom className={classes.Alert}>
          {children}
        </Typography>
      </div>
    );
  }
}

ErrorAlert.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool.isRequired
};

export default withStyles(styles)(ErrorAlert);
